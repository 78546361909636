import React from 'react';
import { useController } from 'react-hook-form';
import { createEditor, Descendant, Editor, Transforms, Element as SlateElement, Node } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { Box, Flex } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import { Toolbar } from './Toolbar';
import { useDebounce } from '~/hooks/useDebounce';
import { isJsonString, replaceHtml, serialize } from '~/utils/richText';
import { get, isEqual } from 'lodash';

const MAX_LENGTH = 8000;

const Element = ({ attributes = {}, children, element }: any) => {
    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>;
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>;
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>;
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>;
        case 'list-item':
            return <li {...attributes}>{children}</li>;
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>;
        case 'page-break':
            return (
                <div {...attributes}>
                    {children}
                    <hr contentEditable={false} style={{ userSelect: 'none' }} />
                </div>
            );
        default:
            return <p {...attributes}>{children}</p>;
    }
};

const Leaf = ({ attributes = {}, children, leaf }: any) => {
    if (leaf.bold) children = <strong>{children}</strong>;
    if (leaf.code) children = <code>{children}</code>;
    if (leaf.italic) children = <em>{children}</em>;
    if (leaf.underline) children = <u>{children}</u>;
    return <span {...attributes}>{children}</span>;
};

const defaultValue: Descendant[] = [{ type: 'paragraph', children: [{ text: '' }] }];

const getDefaultValue = (controlledValue?: any) => {
    let value = controlledValue?.text || controlledValue;

    /** fixes: [Slate] value is invalid! Expected a list of elements but got: "[]" @/einsatzberichte */
    if (value === '[]') return defaultValue;

    if (isJsonString(value)) {
        /** fixes: [Slate] value is invalid! Expected a list of elements but got: {"text":"[{\"type\":\"paragraph\"... */
        if (JSON.parse(value)?.text) value = JSON.parse(value).text;
        if (isJsonString(JSON.parse(value))) value = JSON.parse(value);
    }

    if (
        Array.isArray(value) &&
        value?.[0]?.children?.some(({ text = '' }) => /<[^>]+>|&[a-zA-Z0-9#]+;/g.test(text))
    ) {
        return [
            { type: 'paragraph', children: [{ text: replaceHtml(serialize(value as never[])) }] },
        ];
    } else if (Array.isArray(value) && isValidSlateContent(value)) {
        return value;
    }

    return defaultValue;
};

function isValidSlateContent(nodes: any) {
    return Array.isArray(nodes) && nodes.every((node) => Node.isNode(node));
}

function RichTextEditor({ control, name, ...props }: any) {
    const [key, setKey] = React.useState(() => Date.now());
    const initRef = React.useRef(0);
    const {
        field: { onChange, value: remoteValue },
        formState: { errors },
    } = useController({ name, control, defaultValue: props?.defaultValue || defaultValue });
    const hasError = get(errors, name);
    const [value, setValue] = React.useState<Descendant[]>(defaultValue);

    React.useEffect(() => {
        if (remoteValue && initRef.current === 0) {
            if (!isEqual(remoteValue, defaultValue)) {
                initRef.current = 1;
                setValue(
                    (isValidSlateContent(remoteValue)
                        ? remoteValue
                        : getDefaultValue(remoteValue)) as Descendant[]
                );
                setKey(Date.now());
            }
        }
    }, [remoteValue, initRef.current]);

    return (
        <RichTextEditorCore
            hasError={hasError}
            onChange={onChange}
            value={value}
            key={key}
            {...props}
        />
    );
}

function RichTextEditorCore({
    onChange,
    hasSnippets,
    isDisabled = false,
    canBeCopied = true,
    value: remoteValue,
    blurSelection,
    hasError,
    ...props
}: any) {
    const [textLength, setTextLength] = React.useState(0);
    const toastShownRef = React.useRef(false);
    const [value, setValue] = React.useState<Descendant[]>(() => remoteValue);
    const debouncedvalue = useDebounce(value, 50);
    const toast = useToast();

    const editor = React.useMemo(() => withHistory(withReact(createEditor())), []);
    const renderElement = React.useCallback((props: any) => <Element {...props} />, []);
    const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, []);

    const divRef = React.useRef<HTMLDivElement>(null);
    const [, setFocused] = React.useState(false);
    const savedSelection = React.useRef(editor.selection);

    React.useEffect(() => {
        isValidSlateContent(debouncedvalue) && onChange?.(debouncedvalue);
    }, [debouncedvalue]);

    const onFocus = React.useCallback(() => {
        setFocused(true);
        if (!editor.selection && value?.length) {
            Transforms.select(editor, savedSelection.current ?? Editor.end(editor, []));
        }
    }, [editor]);

    const onBlur = React.useCallback(() => {
        setFocused(false);
        savedSelection.current = editor.selection;
    }, [editor]);

    const focusEditor = React.useCallback(
        (e: React.MouseEvent) => {
            if (e.target === divRef.current) {
                ReactEditor.focus(editor);
                e.preventDefault();
            }
        },
        [editor]
    );

    const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const keys = {
            Enter: () => {
                const options: Partial<SlateElement> = {
                    type: 'paragraph',
                    children: [{ text: '' }],
                };
                const [idx] = editor?.selection?.anchor?.path || [];
                const prevChild: any = editor?.children?.[idx === 0 ? idx : idx - 1];
                console.log('onKeyUp', editor);
                if (prevChild?.type === 'page-break') {
                    Transforms.setNodes(editor, options);
                }
            },
        };
        keys?.[event?.key as keyof typeof keys]?.();
    };
    const insertNode = React.useCallback(
        (node: any) => {
            if (editor.selection) {
                Transforms.select(editor, editor.selection);
            }
            Transforms.insertNodes(editor, node);
        },
        [editor]
    );
    const onSlateChange = (newValue: Descendant[]) => {
        const textContent = Editor.string(editor, []);

        if (textContent) setTextLength(textContent.length);

        if (textContent.length <= MAX_LENGTH) {
            setValue(newValue);
            toastShownRef.current = false;
        } else {
            Transforms.delete(editor, {
                distance: textContent.length - MAX_LENGTH,
                unit: 'character',
            });
            if (!toastShownRef.current) {
                toast({
                    title: 'Maximale Textlänge überschritten',
                    description: `Der Text darf ${MAX_LENGTH} Zeichen nicht überschreiten`,
                    status: 'warning',
                    duration: 2000,
                    isClosable: true,
                });
                toastShownRef.current = true;
            }
        }
    };

    const disabledProps = isDisabled
        ? {
              borderColor: '#F1F4F6',
              pointerEvents: canBeCopied ? 'auto' : 'none',
              color: 'blackAlpha.700',
              cursor: canBeCopied ? 'auto' : 'not-allowed',
              bg: '#F2F2F2',
          }
        : {
              borderColor: hasError ? 'red.500' : '#ccc',
              pointerEvents: 'auto',
              color: 'revert',
              bg: 'revert',
          };

    return (
        <Box ref={divRef} onMouseDown={focusEditor}>
            <Slate editor={editor} value={value} onChange={onSlateChange}>
                <Toolbar
                    isDisabled={isDisabled}
                    hasSnippets={hasSnippets}
                    insertNode={insertNode}
                />
                <Box cursor={isDisabled ? 'not-allowed' : 'revert'}>
                    <Box
                        sx={{
                            ul: { marginLeft: '20px' },
                            ol: { marginLeft: '20px' },
                        }}
                        borderWidth={hasError ? 2 : 1}
                        borderRadius={6}
                        p={2}
                        mt={2}
                        minH={100}
                        {...disabledProps}
                        {...props}
                    >
                        <Editable
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onFocus={onFocus}
                            placeholder="Bitte geben sie hier Text ein…"
                            onBlur={onBlur}
                            onKeyUp={onKeyUp}
                            spellCheck
                            autoFocus
                        />
                    </Box>
                    <Flex
                        fontSize="sm"
                        justifyContent="flex-end"
                        color={textLength > MAX_LENGTH ? 'red.500' : 'gray.500'}
                    >
                        {`${textLength}/${MAX_LENGTH}`}
                    </Flex>
                </Box>
            </Slate>
        </Box>
    );
}

export { RichTextEditor };
