import { useQuery } from '@apollo/client';
import { SelectSubprojectResidentialUnitsDocument, SortOrder } from '~/gql/ucpw/graphql';

export const useEventSubprojectResidentialUnitsDialog = (subprojektId: number | undefined) => {
    const context = { clientName: 'ucpw' };
    const variables = {
        limit: 50,
        orderBy: [{ wohneinheit: { istGesamtobjekt: SortOrder.Asc } }],
        filter: { subprojektId },
    };
    const { data, loading } = useQuery(SelectSubprojectResidentialUnitsDocument, {
        variables,
        context,
        fetchPolicy: 'network-only',
        skip: !subprojektId,
    });
    const subprojectResidentialUnits = data?.items?.items || [];
    const refetchQueries = [
        {
            query: SelectSubprojectResidentialUnitsDocument,
            context,
            variables,
        },
    ];

    return { loading, refetchQueries, subprojectResidentialUnits };
};
