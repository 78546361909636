import React, { useState } from 'react';
import {
    ModalContent,
    Button,
    ModalBody,
    Modal,
    ModalOverlay,
    VStack,
    FormLabel,
    Checkbox,
    Text,
    HStack,
    Spinner,
    ButtonProps,
} from '@chakra-ui/react';
import { CustomModalHeader } from '~/components/CustomModalHeader';
import { useWattro } from '~/pages/projects/ui/Wattro/hooks/useWattro';
import { Wattro } from '~/components/Wattro';
import { useDialog } from '~/hooks/useDialog';
import { useEventSubprojectResidentialUnitsDialog } from './hooks/useEventSubprojectResidentialUnitsDialog';
import { join } from '~/utils';

type SubprojectResidentialUnit = { id: number; wattroProjectExists: boolean; isChecked: boolean };

export const EventSubprojectResidentialUnitsDialog = () => {
    const { type, data, onClose, ...dialog } = useDialog();
    const { event = {}, onSubmit } = data;
    const isOpen = dialog?.isOpen && type === 'eventSubprojectResidentialUnits';
    const {
        title,
        hint,
        canWattro,
        createWattroProjects,
        loading,
        residentialUnitsLoading,
        setUnits,
        onCheck,
        units,
        isOutlook,
        pointerEvents,
        cursor,
    } = useEventEvaluations(event);

    const onSave = async () => {
        await onSubmit({
            ...event,
            units: units?.reduce(
                (acc: number[], { id, isChecked }) => (isChecked ? [...acc, id] : acc),
                []
            ),
        });
        if (canWattro && !units.every((unit: any) => unit?.wattroProjectExists)) {
            await createWattroProjects(units);
        }
        onClose();
    };

    React.useEffect(() => {
        if (!isOpen) setUnits([]);
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent rounded="none" maxWidth="container.md">
                <CustomModalHeader title={title}>
                    <HStack>
                        <Zurueck onClick={onClose} />
                        <Speichern isLoading={loading} onClick={onSave} isDisabled={isOutlook} />
                    </HStack>
                </CustomModalHeader>
                <ModalBody
                    pb={6}
                    data-test-id="subproject-residential-units-modal-body"
                    cursor={cursor}
                >
                    <VStack alignItems="start" spacing={2} mb={5} pointerEvents={pointerEvents}>
                        <FormLabel>Wohneinheit</FormLabel>
                        {residentialUnitsLoading ? (
                            <VStack pl="8">
                                <Spinner size="sm" color="gray.400" />
                            </VStack>
                        ) : units?.length ? (
                            units?.map(
                                ({
                                    id,
                                    isChecked,
                                    wattroProjectExists,
                                    wohneinheit,
                                    tenants = '',
                                }: any) => (
                                    <HStack key={id}>
                                        <Checkbox
                                            isChecked={isChecked}
                                            onChange={(e) => onCheck(e, id)}
                                            isDisabled={canWattro && wattroProjectExists}
                                        >
                                            {wohneinheit?.bezeichnung || ''}
                                            {tenants && ` / ${tenants}`}
                                        </Checkbox>
                                        {wattroProjectExists && <Wattro />}
                                    </HStack>
                                )
                            )
                        ) : (
                            <VStack>
                                <Text fontSize="xs" color="gray.400">
                                    Keine Wohneinheiten
                                </Text>
                            </VStack>
                        )}
                    </VStack>
                    <Text fontWeight="bold">Hinweis:</Text>
                    <Text>{hint}</Text>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

function useEventEvaluations(event: any) {
    const isOutlook = event?.typeSnippetId?.label === 'Externer Termin';
    const cursor = isOutlook ? 'not-allowed' : 'auto';
    const pointerEvents: React.CSSProperties['pointerEvents'] = isOutlook ? 'none' : 'auto';
    const [units, setUnits] = useState<SubprojectResidentialUnit[]>([]);
    const subprojectId = event?.subprojekt?.id;
    const {
        loading: residentialUnitsLoading,
        subprojectResidentialUnits,
        refetchQueries,
    } = useEventSubprojectResidentialUnitsDialog(subprojectId);
    const { wattroCreateProjectForSubprojektWohneinheit, loading: wattroLoading } = useWattro({
        refetchQueries,
    });
    const loading = residentialUnitsLoading || wattroLoading;
    const canWattro =
        !event?.id &&
        event?.typeSnippetId?.label === 'Aufbau' &&
        event.projekttyp?.name === 'Trocknung';
    const wattroHint = subprojectResidentialUnits?.some?.(
        (unit: any) => unit?.wohneinheit?.istGesamtobjekt === false
    )
        ? 'Wählen Sie hier die Wohneinheiten aus, in denen Trocknungsgeräte aufgebaut werden sollen. Für jede ausgewählte Wohneinheit legen wir Ihr Projekt in wattro an.'
        : 'Es wurden noch keine Wohneinheiten angelegt. Die Anlage der wattro Projekte könnte unvollständig sein. Bitte prüfen Sie die Wohneinheiten und legen den wattro Termin gegebenenfalls nachträglich aus dem Geräteeinsatz an.';
    const hint = canWattro
        ? wattroHint
        : 'Wählen Sie hier die Wohneinheiten aus, auf die sich Ihr Termin bezieht.';
    const title = isOutlook
        ? 'Wohneinheit für Termin ansehen'
        : canWattro
        ? 'Wohneinheit für wattro-Aufbau wählen'
        : 'Wohneinheit für Termin wählen';

    const createWattroProjects = async (units: SubprojectResidentialUnit[]) => {
        return Promise.all(
            units
                .filter((unit) => unit.isChecked && !unit.wattroProjectExists)
                .map(({ id: subprojektWohneinheitId }) =>
                    wattroCreateProjectForSubprojektWohneinheit({
                        variables: { subprojektWohneinheitId },
                    })
                )
        );
    };

    const onCheck = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
        setUnits((prev: any) =>
            prev.map((unit: SubprojectResidentialUnit) => {
                const isChecked = unit.id === id ? e.target.checked : unit.isChecked;
                return { ...unit, isChecked };
            })
        );
    };

    React.useEffect(() => {
        if (subprojectResidentialUnits.length) {
            const eventSubprojectResidentialUnits = (
                event?.eventSubprojectResidentialUnits || []
            )?.map((unit: { subprojektWohneinheitId: number }) => unit?.subprojektWohneinheitId);
            setUnits(
                subprojectResidentialUnits?.map?.(({ beteiligter = [], ...unit }: any) => {
                    const residentialUnitIdGotSaved = eventSubprojectResidentialUnits.includes(
                        unit.id
                    );
                    return {
                        ...unit,
                        tenants: join(
                            beteiligter?.reduce(
                                (acc: string[], { kontakt }: any) => [
                                    ...acc,
                                    join([
                                        kontakt?.firma1,
                                        kontakt?.firma2,
                                        kontakt?.name,
                                        kontakt?.vorname,
                                    ]),
                                ],
                                []
                            ),
                            ' / '
                        ),
                        isChecked: canWattro
                            ? !unit?.wohneinheit?.istGesamtobjekt || unit?.wattroProjectExists
                            : residentialUnitIdGotSaved,
                    };
                })
            );
        }
    }, [subprojectResidentialUnits, canWattro, event]);

    return {
        title,
        hint,
        canWattro,
        isOutlook,
        cursor,
        pointerEvents,
        createWattroProjects,
        loading,
        residentialUnitsLoading,
        setUnits,
        onCheck,
        units,
    };
}

function Zurueck({ onClick }: ButtonProps) {
    return (
        <Button
            onClick={onClick}
            data-test-id="subproject-residential-units-cancel-button"
            variant="outline"
        >
            Zurück
        </Button>
    );
}

function Speichern({ isLoading, onClick, isDisabled = false }: ButtonProps) {
    return (
        <Button
            onClick={onClick}
            isLoading={isLoading}
            isDisabled={isDisabled}
            data-test-id="subproject-residential-units-save-button"
            colorScheme="blue"
        >
            Speichern
        </Button>
    );
}
