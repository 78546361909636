import { EventSubprojectResidentialUnitsDialog } from '~/pages/projects/ui/Calendar/EventSubprojectResidentialUnitsDialog';
import { EmergencyProjectsDialog } from '~/pages/projects/ui/Wattro/EmergencyProjectsDialog';

export const DialogProvider = () => {
    return (
        <>
            <EventSubprojectResidentialUnitsDialog />
            <EmergencyProjectsDialog />
        </>
    );
};
