import { create } from 'zustand';

type ModalType =
    | 'createWattro'
    | 'selectProject'
    | 'chooseResidentialUnit'
    | 'eventSubprojectResidentialUnits';
type ModalData = Record<string, any>;
type ModalStore = {
    type: ModalType | null;
    data: ModalData;
    isOpen: boolean;
    onOpen: (type: ModalType, data?: ModalData) => void;
    onClose: () => void;
};

export const useDialog = create<ModalStore>((set) => ({
    type: null,
    data: {},
    isOpen: false,
    onOpen: (type, data = {}) => set({ type, data, isOpen: true }),
    onClose: () => set({ type: null, isOpen: false }),
}));
