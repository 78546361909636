import { gql, useApolloClient } from '@apollo/client';
import log from '~/log';

const PublishEvent = gql`
    mutation PublishEvent($event: EventInput!) {
        publish(event: $event) {
            EventId
        }
    }
`;

export function usePublishEvent() {
    const client = useApolloClient();

    async function publishEvent(event: {
        source: string;
        name: string;
        payload: Record<string, unknown>;
    }) {
        const response = await client.mutate({
            mutation: PublishEvent,
            variables: {
                event: {
                    source: event.source,
                    name: event.name,
                    payload: JSON.stringify(event.payload),
                },
            },
        });
        log.debug('publishEvent response', response);
    }

    return { publishEvent };
}
